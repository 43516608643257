<template>
  <div class="timeline-item is-primary">
    <div class="timeline-marker is-primary"></div>
    <div class="timeline-content" data-aos="fade-up" data-aos-once="true">
      <p class="heading">{{ industry }}</p>
      <p>{{ role }}</p>
      <slot></slot>
    </div>
  </div>
</template>

<script>
  export default {
    name: "TimelineItem",
    props: {
      industry: String,
      role: String,
    }
  }
</script>

