<template>
  <div id="app" class="has-text-centered">
    <div><Hero /></div>
    <div class="has-background-white-ter"><Doing /></div>
    <div class="has-background-white"><Skills /></div>
    <div class="has-background-white-ter"><Experience /></div>
    <div class="has-background-white"><Education /></div>
    <div class="has-background-black"><Footer /></div>
  </div>
</template>

<script>
  import Hero from "./sections/Hero";
  import Doing from "./sections/Doing";
  import Skills from "./sections/Skills";
  import Education from "./sections/Education";
  import Experience from "./sections/Experience";
  import Footer from "./sections/Footer";
  import AOS from 'aos';
  import 'aos/dist/aos.css';

  export default {
    name: 'app',
    components: {
      Hero,
      Doing,
      Skills,
      Experience,
      Education,
      Footer,
    },
    created () {
      AOS.init()
    },
  }
</script>

<style lang="scss">
  @import "../global";
  @import "~bulma/sass/base/all";

  @import "~bulma/sass/layout/hero";
  @import "~bulma/sass/layout/section";
  @import "~bulma/sass/grid/all";
  @import "~bulma-timeline/src/sass";

  @import "~bulma/sass/elements/title";
  @import "~bulma/sass/elements/image";
  @import "~bulma/sass/elements/box";
  @import "~bulma/sass/elements/icon";
  @import "~bulma/sass/elements/container";
  @import "~bulma/sass/elements/progress";
  @import "~bulma/sass/elements/button";
  @import "~bulma/sass/elements/tag";

  @import "~bulma/sass/components/modal";
  @import "~bulma/sass/components/card";

  @import "~@fortawesome/fontawesome-free/css/fontawesome.min.css";
  @import "~@fortawesome/fontawesome-free/css/solid.min.css";
  @import "~@fortawesome/fontawesome-free/css/brands.min.css";
</style>
