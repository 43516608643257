<template>
  <div>
    <a @click="toggle">Impressum</a>
    <div class="modal is-clipped" v-bind:class="{'is-active' : isOpen}">
      <div class="modal-background"></div>
      <div class="modal-card has-text-black">
        <header class="modal-card-head">
          <p class="modal-card-title">Impressum</p>
          <a class="close is-rounded" aria-label="close" @click="toggle">
            <span class="icon"><i class="fa fa-times"/></span>
          </a>
        </header>
        <section class="modal-card-body">
          <h1 class="title">Angabem gemäss § 5 TMG</h1>
          <p>Florian Schmatzer IT Consulting</p>
          <p>Mirabellen-Hof 4</p>
          <p>85591 Vaterstetten</p>

          <h1 class="title">Kontakt</h1>
          <p>Telefon: +49 173 99 16 16 6</p>
          <p>E-Mail: kontakt@schmatzer-consulting.de</p>

          <h1 class="title">Verantwortlich für den Inhalt nach § 55 ABS. 2 RSTV</h1>
          <p>Florian Schmatzer</p>
          <p>Mirabellen-Hof 4</p>
          <p>85591 Vaterstetten</p>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Imprint",

  data: function () {
    return {
      isOpen: false,
    }
  },

  methods: {
    toggle: function (e) {
      e.preventDefault();
      this.isOpen = !this.isOpen;
      this.isOpen
          ? document.documentElement.classList.add('is-clipped')
          : document.documentElement.classList.remove('is-clipped');
    }
  },
}
</script>

<style scoped lang="scss">
@import "../../global";

a.close {
  color: $black;

  &:hover {
    color: $grey-light;
  }
}

.modal-card-body {
  padding-top: 0;
}

.title:not(:last-child) {
  margin-top: 1em;
  margin-bottom: 0.5em;
  font-size: 1.25em;
}
</style>
