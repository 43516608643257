<template>
  <section id="doing" class="section container">
    <h1 class="title">Was ich mache</h1>
    <p>
      Ich bin Softwareentwickler und habe meinen Schwerpunkt in der Webentwicklung.
      Dabei biete ich meinen Kunden individuelle Softwarelösungen mit modernster Webtechnologie
      und begleite Sie über den gesamten Entwicklungsprozess bis zur fertigen Lösung.
    </p>
    <p>
      Gerne lasse ich Ihnen auf
      <ContactEmail name="Anfrage"/>
      meinen aktuellen CV mit Projektauflistung zukommen,
      damit Sie sich ein Bild von meinen bisherigen Leistungen machen können.
    </p>

    <div class="tile is-ancestor" v-bind:class="{ 'is-vertical': windowWidth < 1216}">
      <div class="tile" v-bind:class="{ 'is-8': windowWidth >= 1216}">
        <div class="tile is-parent">
          <div class="tile is-child box" data-aos="fade-right" data-aos-offset="200" data-aos-delay="0" data-aos-once="true">
            <IconBox icon="fas fa-code" title="Software Entwicklung">
              Als Entwickler beschäftige ich mich derzeit gerne mit Cloudtechnologien sowie Microservice-Architekturen.
              Unter Berücksichtigung neuester DevOps-Methoden entwickle ich nachhaltige, testgetriebene Lösungen,
              die auf Ihr Projekt zugeschnitten sind.
            </IconBox>
          </div>
        </div>
        <div class="tile is-parent">
          <div class="tile is-child box" data-aos="fade-right" data-aos-offset="200" data-aos-delay="150" data-aos-once="true">
            <IconBox icon="fas fa-bezier-curve" title="Software Architektur">
              Als Basis für den Erfolg und die Wartbarkeit eines Softwaresystems, steht die Architektur. Die Kunst
              des Architekten besteht darin, alle Anforderung zu verstehen und die Brücke zwischen Projektleitung und
              Entwicklern zu bauen. Gerne unterstütze ich Sie bei der Definition und dem Bau Ihres IT-Projekts.
            </IconBox>
          </div>
        </div>
      </div>
      <div class="tile is-parent">
        <div class="tile is-child box" data-aos="fade-right" data-aos-offset="200" data-aos-delay="300" data-aos-once="true">
          <IconBox icon="fas fa-drafting-compass" title="IT-Beratung">
            Durch meinen langjährigen Einsatz als IT-Berater und meinem umfangreichen und fundierten Know-How
            im Bereich der Softwareentwicklung, stehe ich Ihnen und Ihrem Team bei der Lösung komplexer Probleme
            gerne als technischer Senior IT-Berater zur Seite.
          </IconBox>
        </div>
      </div>
    </div>

  </section>
</template>

<script>
  import IconBox from "../components/IconBox";
  import ContactEmail from "../components/ContactEmail";

  export default {
    name: "Doing",
    components: {
      IconBox,
      ContactEmail,
    },
    data() {
      return {
        windowWidth: window.innerWidth
      };
    },

    methods: {
      handleResize(event) {
        this.windowWidth = event.currentTarget.innerWidth;
      },
    },

    mounted() {
      window.addEventListener('resize', this.handleResize);
    },

    beforeDestroy: function () {
      window.removeEventListener('resize', this.handleResize);
    },
  }
</script>

<style lang="scss" scoped>
  .section p {
      max-width: 700px;
      margin: 0 auto 3em auto;
      word-break: break-word;
  }
</style>
