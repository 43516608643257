<template>
  <div class="container has-text-left" >
    <h1 class="title is-5">
      <span class="icon">
        <i class="fa-fw" v-bind:class="icon"/>
      </span>
      {{ title }}
    </h1>
    <span class="subtitle is-6">{{ subtitle }}</span>
    <progress class="progress is-primary is-small" v-bind:value="value" max="100" />
  </div>
</template>

<script>
  export default {
    name: "ProgressBar",
    props: {
      title: String,
      subtitle: String,
      value: String,
      icon: String,
    }
  }
</script>

<style scoped lang="scss">
  .container {
    margin-bottom: 3rem;
  }
  .title, .title:not(:last-child) {
    margin-bottom: 0.25em;
  }
  .progress.is-small {
    height: 0.5em;
  }
  .subtitle {
    font-weight: normal;
  }
  .icon {
    margin-bottom: 0.25rem;
  }
</style>
