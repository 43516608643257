<template>
  <section id="skills" class="section container">
    <h1 class="title">Was ich kann</h1>
    <div class="columns">
      <div class="column is-half" data-aos="fade-up" data-aos-once="true">
        <div class="content">
          <ProgressBar title="Java" icon="fab fa-java" subtitle="Spring Boot, JEE, Micro Profile, Camel" value="95" />
        </div>
        <div class="content">
          <ProgressBar title="Javascript" icon="fab fa-js-square" subtitle="Angular, Vue.js, Node" value="88" />
        </div>
        <div class="content">
          <ProgressBar title="Web" icon="fab fa-html5" subtitle="HTML, CSS" value="85" />
        </div>
        <div class="content">
          <ProgressBar title="Datenbank" icon="fas fa-database" subtitle="Oracle, PostgreSQL, SQL Server, MongoDB, MySQL" value="93" />
        </div>
        <div class="content">
          <ProgressBar title="Tools" icon="fas fa-toolbox" subtitle="Atlassian, Git, Maven, Gradle, NPM, Webpack" value="90" />
        </div>
      </div>
      <div class="column is-half" data-aos="fade-up" data-aos-once="true" data-aos-delay="150">
        <div class="content">
          <ProgressBar title="Cloud" icon="fab fa-docker" subtitle="Kubernetes, Openshift, Nomad, Docker" value="85" />
        </div>
        <div class="content">
          <ProgressBar title="DevOps" icon="fas fa-project-diagram" subtitle="Jenkins, Gitlab, ELK, Grafana, Prometheus, Thanos" value="87" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  import ProgressBar from "../components/ProgressBar";

  export default {
    name: "Skills",
    components: {
      ProgressBar,
    },
  }
</script>

<style scoped lang="scss">
  .content {
    margin: 0 1.5em 1.5em 1.5em;
  }
</style>
