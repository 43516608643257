<template>
  <section id="experience"  class="section container">
    <h1 class="title ">Meine Erfahrungen</h1>
    <Timeline class="has-text-left" v-bind:class="{ 'is-centered': windowWidth > 769}">
      <TimelineItem industry="IT-Branche" role="Software Entwickler / Architekt">
        <div class="tags">
          <span class="tag is-primary is-light">JEE</span>
          <span class="tag is-primary is-light">Open Liberty</span>
          <span class="tag is-primary is-light">DB2</span>
        </div>
      </TimelineItem>
      <TimelineItem industry="Automobil" role="Software Entwickler / Architekt">
        <div class="tags">
          <span class="tag is-primary is-light">JEE</span>
          <span class="tag is-primary is-light">Angular</span>
          <span class="tag is-primary is-light">Docker</span>
          <span class="tag is-primary is-light">Kubernetes</span>
          <span class="tag is-primary is-light">AWS</span>
          <span class="tag is-primary is-light">PostgreSQL</span>
        </div>
      </TimelineItem>
      <TimelineItem industry="Öffentliche Verwaltung" role="Software Entwickler / Architekt">
        <div class="tags">
          <span class="tag is-primary is-light">Spring Boot</span>
          <span class="tag is-primary is-light">Angular</span>
          <span class="tag is-primary is-light">Ansible</span>
          <span class="tag is-primary is-light">DevOps</span>
          <span class="tag is-primary is-light">Oracle 11g</span>
        </div>
      </TimelineItem>
      <TimelineItem industry="IT-Branche" role="Software Entwickler / Architekt">
        <div class="tags">
          <span class="tag is-primary is-light">Spring Boot</span>
          <span class="tag is-primary is-light">Angular</span>
          <span class="tag is-primary is-light">Docker</span>
          <span class="tag is-primary is-light">SQL Server</span>
          <span class="tag is-primary is-light">MySQL</span>
        </div>
      </TimelineItem>
      <TimelineItem industry="Öffentliche Verwaltung" role="Software Entwickler">
        <div class="tags">
          <span class="tag is-primary is-light">Spring Boot</span>
          <span class="tag is-primary is-light">Kubernetes</span>
          <span class="tag is-primary is-light">ActiveMQ</span>
          <span class="tag is-primary is-light">Oracle 11g</span>
          <span class="tag is-primary is-light">DevOps</span>
          <span class="tag is-primary is-light">Camel</span>
        </div>
      </TimelineItem>
      <TimelineItem industry="IT-Branche" role="Software Entwickler / Architekt">
        <div class="tags">
          <span class="tag is-primary is-light">Spring Boot</span>
          <span class="tag is-primary is-light">Vue.js</span>
          <span class="tag is-primary is-light">MySQL</span>
          <span class="tag is-primary is-light">PostgreSQL</span>
        </div>
      </TimelineItem>
      <TimelineItem industry="IT-Branche" role="Software Entwickler / Architekt">
        <div class="tags">
          <span class="tag is-primary is-light">Spring Boot</span>
          <span class="tag is-primary is-light">Angular</span>
          <span class="tag is-primary is-light">WebSockets</span>
          <span class="tag is-primary is-light">Docker</span>
          <span class="tag is-primary is-light">MySQL</span>
          <span class="tag is-primary is-light">PostgreSQL</span>
        </div>
      </TimelineItem>
      <TimelineItem industry="Automobil" role="Software Entwickler">
        <div class="tags">
          <span class="tag is-primary is-light">JEE</span>
          <span class="tag is-primary is-light">HiveMQ</span>
          <span class="tag is-primary is-light">Angular</span>
          <span class="tag is-primary is-light">Docker</span>
          <span class="tag is-primary is-light">PostgreSQL</span>
          <span class="tag is-primary is-light">DevOps</span>
        </div>
      </TimelineItem>
      <TimelineItem industry="Automobil" role="Software Entwickler">
        <div class="tags">
          <span class="tag is-primary is-light">JEE</span>
          <span class="tag is-primary is-light">HiveMQ</span>
          <span class="tag is-primary is-light">Angular</span>
          <span class="tag is-primary is-light">Android</span>
          <span class="tag is-primary is-light">Docker</span>
          <span class="tag is-primary is-light">Oracle 11g</span>
          <span class="tag is-primary is-light">PostgreSQL</span>
        </div>
      </TimelineItem>
      <TimelineItem industry="IT-Branche" role="Software Entwickler / Architekt">
        <div class="tags">
          <span class="tag is-primary is-light">OSGI</span>
          <span class="tag is-primary is-light">ActiveMQ</span>
          <span class="tag is-primary is-light">Angular</span>
          <span class="tag is-primary is-light">Docker</span>
          <span class="tag is-primary is-light">MySQL</span>
        </div>
      </TimelineItem>
    </Timeline>
  </section>
</template>

<script>
  import Timeline from "../components/Timeline";
  import TimelineItem from "../components/TimelineItem";

  export default {
    name: "Experience",
    components: {
      Timeline,
      TimelineItem
    },

    data() {
      return {
        windowWidth: window.innerWidth
      };
    },

    methods: {
      handleResize(event) {
        this.windowWidth = event.currentTarget.innerWidth;
      },
    },

    mounted() {
      window.addEventListener('resize', this.handleResize);
    },

    beforeDestroy: function () {
      window.removeEventListener('resize', this.handleResize);
    },

  }
</script>

