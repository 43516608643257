<template>
  <section class="hero is-fullheight has-background-black">
    <div class="hero-body">
      <div class="container has-text-centered">
        <figure class="image">
          <img class="is-rounded is-inline-block" src="../assets/profile.jpg" style="height: 256px; width: 256px; z-index:1;">
        </figure>
        <h1 class="title has-text-white">Florian Schmatzer</h1>
        <h2 class="subtitle has-text-white" id="typewriter">Full Stack <span class="highlight">Developer</span></h2>
      </div>
    </div>
    <div class="hero-foot">
      <a @click="scrollDown()" class="has-text-white">
        <i class="fas fa-chevron-down fa-2x" />
      </a>
    </div>
  </section>
</template>

<script>
  import Typewriter from 'typewriter-effect/dist/core';
  import GraphemeSplitter from "grapheme-splitter";

  export default {
    name: 'Hero',

    mounted() {
      const splitter = string => {
        const splitter = new GraphemeSplitter();
        return splitter.splitGraphemes(string);
      };
      const typewriter = new Typewriter(document.getElementById("typewriter"), {
        loop: false,
        delay: 75,
        stringSplitter: splitter
      });

      typewriter
          .typeString('Full Stack Dev')
          .pauseFor(250)
          .deleteChars(3)
          .pauseFor(100)
          .typeString('<span class="highlight">Developer</span>')
          .start();
    },
    methods: {
      scrollDown() {
        document.getElementById("doing").scrollIntoView({
          behavior: 'smooth'
        });
      }
    },

  }
</script>

<style scoped lang="scss">
  @import "../../global";

  .subtitle {
    font-weight: bold;

    /deep/ .highlight {
      color: $primary;
    }
  }

  .hero-foot {
    height: 100px;
  }

  a {
    :hover {
      color: $primary;
    }
  }
</style>
