<template>
  <div>
    <span class="icon has-text-primary is-large">
      <i v-bind:class="icon" class="fa-2x fa-lg"/>
    </span>
    <h1 class="title is-5">{{ title }}</h1>
    <p><slot></slot></p>
  </div>
</template>

<script>
  export default {
    name: "IconBox",
    props: {
      icon: String,
      title: String,
    }
  }
</script>

<style lang="scss">
  .icon {
    margin-bottom: 1.5em;
  }
</style>
