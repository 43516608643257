<template>
  <section id="education" class="section container">
    <h1 class="title">Ausbildung</h1>
    <div class="tile is-ancestor">
      <div class="tile is-parent">
        <div class="tile is-child box" data-aos="fade-right" data-aos-offset="100" data-aos-delay="0" data-aos-once="true">
          <IconBox icon="fas fa-user-graduate" title="Master of Sience">
            Hochschule München<br/>
            2010 - 2012
          </IconBox>
        </div>
      </div>
      <div class="tile is-parent">
        <div class="tile is-child box" data-aos="fade-right" data-aos-offset="100" data-aos-delay="150" data-aos-once="true">
          <IconBox icon="fas fa-user-graduate" title="Bachelor of Sience">
            Hochschule München<br/>
            2006 - 2010
          </IconBox>
        </div>
      </div>
      <div class="tile is-parent">
        <div class="tile is-child box" data-aos="fade-right" data-aos-offset="100" data-aos-delay="300" data-aos-once="true">
          <IconBox icon="fas fa-chalkboard-teacher" title="Informatikkaufmann">
            Fiducia IT AG<br/>
            2003 - 2006
          </IconBox>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  import IconBox from "../components/IconBox";

  export default {
    name: "Education",
    components: {
      IconBox,
    },
  }
</script>

