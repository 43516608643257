<template>
  <a v-bind:href="email">{{ name }}</a>
</template>

<script>
  export default {
    name: "ContactEmail",
    props: {
      name: {
        type: String,
        default: 'kontakt@schmatzer-consulting.de'
      },
      email: {
        type: String,
        default: 'mailto:kontakt@schmatzer-consulting.de',
      },
    }
  }
</script>

