<template>
  <section class="section container has-text-left has-text-white">
    <div>
        <Imprint/>
        <Disclaimer/>
    </div>
    <div class="contact">
      <p>E-Mail:
        <ContactEmail/>
      </p>
      <p>Mobile: <a href="tel:+491739916166">+49 173 99 16 16 6</a></p>
      <p>© 2023 Florian Schmatzer</p>
    </div>
  </section>
</template>

<script>
  import Disclaimer from "../dialogs/Disclaimer";
  import Imprint from "../dialogs/Imprint";
  import ContactEmail from "../components/ContactEmail";

  export default {
    name: "Footer",
    components: {
      Disclaimer,
      Imprint,
      ContactEmail,
    },
  }
</script>

<style scoped lang="scss">
  @import "../../global";

  /deep/ a {
    color: $link-invert;
  }

  .contact {
    margin-top: 3rem;
  }

</style>
